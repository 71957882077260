angular.module('fingerink')
    .directive('planCard', function () {
        return {
            restrict: 'E',
            replace: 'false',
            templateUrl: 'web/main/buy/directives/planCard.tpl.html',
            scope: {
                price: '=',
                buy:'&?',
                producto: '='
            },
            controllerAs: 'controller',
            controller: 'planCardCtrl'
        };
    })
    .controller('planCardCtrl', function ($rootScope, $scope) {
        var that = this;
    });
